import { Block } from '@/components/base/Block'
import { Heading3 } from '@/components/base/Heading'
import { Text3 } from '@/components/base/Text'
import { align } from '@/styles/display'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { memo } from 'react'

export const AccessDeniedPage = memo((): JSX.Element => {
  return (
    <Block
      textAlign={align.LEFT}
      maxWidth="600px"
      margin={{ horizontal: spaceStyle.AUTO }}
    >
      <Heading3
        fixedSPfontSize
        fontSizeSP={fontSize.L}
        margin={{ vertical: spaceStyle.XL }}
      >
        アクセスしようとしたページは表示できません。
      </Heading3>
      <Text3 margin={{ vertical: spaceStyle.NONE }}>
        リクエストが無効です。リクエストに必要な情報を提供していることを確認してください。
        （エラーコード：403）
      </Text3>
    </Block>
  )
})

AccessDeniedPage.displayName = 'AccessDeniedPage'
