import { Block } from '@/components/base/Block'
import { Heading3 } from '@/components/base/Heading'
import { Text3 } from '@/components/base/Text'
import { align } from '@/styles/display'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { memo } from 'react'

interface InternalPageProps {
  errorMessage?: string
}

export const InternalPage = memo(
  ({ errorMessage }: InternalPageProps): JSX.Element => {
    const defaultErrorMessage =
      '不明なエラーが発生している可能性がございます。大変申し訳ございませんが、しばらく経ってから 再度お試しください。'

    const hasErrorMessage = errorMessage != null && errorMessage !== ''

    return (
      <Block
        textAlign={align.LEFT}
        maxWidth="600px"
        margin={{ horizontal: spaceStyle.AUTO }}
      >
        <Heading3
          fixedSPfontSize
          fontSizeSP={fontSize.L}
          margin={{ vertical: spaceStyle.XL }}
        >
          {hasErrorMessage
            ? 'アクセスしようとしたページは表示できません。'
            : '不明なエラーが発生しました。'}
        </Heading3>
        <Text3 margin={{ vertical: spaceStyle.NONE }}>
          {errorMessage ?? defaultErrorMessage}
          {hasErrorMessage && '（エラーコード：500）'}
        </Text3>
      </Block>
    )
  }
)

InternalPage.displayName = 'InternalPage'
