'use client'

import { useLocation, useRoutes } from 'react-router-dom'
import { appRoutes } from './routes/routes'
import { ThemeProvider } from 'react-jss'
import { useApp } from '@/hooks/useApp'
import useFaviconAndMeta from '@/hooks/useFaviconAndMeta'
import Layout from './components/Layout'
import { ErrorBoundary } from 'react-error-boundary'
import { InternalPage } from './feature/Error/pages/500'

export const Index = (): JSX.Element => {
  const element = useRoutes(appRoutes)
  const app = useApp()
  useFaviconAndMeta({ app })
  const location = useLocation()

  if (app.isLoading) return <></>

  return (
    <Layout>
      <ErrorBoundary key={location.pathname} fallbackRender={fallbackRender}>
        <ThemeProvider theme={app.theme}>{element}</ThemeProvider>
      </ErrorBoundary>
    </Layout>
  )
}

function fallbackRender({ error }: any): JSX.Element {
  return <InternalPage errorMessage={error.message} />
}
